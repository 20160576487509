<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.0214844" width="32" height="32" rx="8" fill="#EBF5FF" />
    <path
      d="M14.1257 21C13.9081 21.0009 13.6988 20.9089 13.5426 20.7437L9.6071 16.5775C9.52865 16.4939 9.46606 16.3943 9.4229 16.2844C9.37974 16.1745 9.35684 16.0563 9.35553 15.9367C9.35287 15.6952 9.43827 15.4624 9.59293 15.2896C9.7476 15.1168 9.95886 15.0181 10.1802 15.0152C10.4016 15.0123 10.615 15.1054 10.7734 15.2742L14.1291 18.8251L21.2703 11.2588C21.4289 11.0901 21.6425 10.997 21.864 11.0001C22.0856 11.0031 22.2969 11.1021 22.4516 11.2752C22.6062 11.4482 22.6915 11.6812 22.6887 11.9229C22.6859 12.1646 22.5952 12.3952 22.4366 12.5639L14.7089 20.7437C14.5527 20.9089 14.3433 21.0009 14.1257 21Z"
      fill="#1C64F2"
    />
  </svg>
</template>
