<template>
  <notifications class="flik-notif">
    <template #body="props">
      <div
        class="vue-notification-template flik-notif__content"
        :class="props.item.type"
        @click="props.close"
      >
        <span
          :class="
            props.item.type === 'success'
              ? 'notification-title'
              : 'notification-title__with-border'
          "
        >
          <IconsCheckSquare
            v-if="props.item.type === 'success'"
            class="text-green-700"
          />
          <IconsExclamation
            v-else-if="props.item.type === 'error'"
            class="h-5 w-5 text-red-700"
          />
          <IconsInfoCircle
            v-else-if="props.item.type === 'info'"
            class="h-4 w-4 text-blue-700"
          />
          <!-- {{ props.item.title }} -->
        </span>
        <p class="notification-content">
          {{ props.item.text }}
        </p>
      </div>
    </template>
  </notifications>
</template>
